.loginContainer {
    background-image: url('../../assets/image/login_image.png');
    height: 950px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.loginIndependiCareLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 650px;
    padding-left: 15px;
    padding-right: 15px;
}

.loginImage {
    background-color: #fff;
    width: 100%;
    max-width: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
}

.Loginimage {
    width: 100%;
    max-width: 235px;
    height: auto;
    margin: 11px 0px 11px 0px;
}

.loginFrombackground {
    background-color: #fff;
    width: 100%;
    max-width: 650px;
    border-radius: 9px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    padding-bottom: 30px
}

.loginFromtext {
    padding-top: 50px;
    text-align: center;
}

.loginFromtext h1 {
    font-size: 31px;
    font-weight: 600;
    line-height: 40px;
    color: #000000;
}

.loginFromtext p {
    font-size: 20px;
    font-style: italic;
    font-family: 'Inter';
    font-weight: 400;
    color: #828282;
    line-height: 24px;
}

.loginForm {
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
}

.forGotpassword p {
    text-align: right;
    margin-top: 15px;
    color: #5DA128;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 35px;
}

.labelUsername {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Inter';
    color: #333333;
    line-height: 20px;
    margin-bottom: 12px
}

.accoutText p {
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    margin-bottom: 0px;
}

/* .signUpTabInput {
    border: 1px solid red;
} */
.signUpTabInput {
    margin-bottom: 35px;
}

.loginTabInput input {
    height: 45px !important;
    border-radius: 3px !important;
    border: 2px solid #C2C2C2 !important;

}

.loginButtonmain {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.loginLable {
    margin-bottom: 35px;
}

.loginBtn {
    background-color: #1D4488;
    color: #fff;
    height: 42px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    font-family: inter;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100px;
}

.loginBtn:hover {
    background-color: #1D4488;
    color: #fff;
}

.Login_signuptext {
    color: #1D4488;
}

/********forgotpasswordcss****/
.fotGotepassowtdtext {
    padding: 40px;
    text-align: center;
}

.fotGotepassowtdtext p {
    font-size: 20px;
    font-style: italic;
    font-family: 'Inter';
    font-weight: 400;
    color: #828282;
    line-height: 30px;
}

.backButtonLogin {
    display: flex;
    align-items: center;
    justify-content: center;
}

.backButtonmain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;
}

.sendLinkBtn {
    background-color: #1D4488;
    color: #fff;
    height: 42px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    font-family: inter;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 163px;
}

.fotGotepassowtdtext h1 {
    font-size: 31px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #000000;
    /* font-family: 'Inter'; */
}

.forgotPasswordFiled {
    margin-top: 0;
    margin-left: 50px;
    margin-right: 50px;
}

.backSingText p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 600;
    color: #5DA128;
    line-height: 20px;
    margin-left: 12px;
}

.backArrow img {
    width: 7px;
}

.sendLinkBtn:hover {
    background-color: #1d4488;
}

/***pass code*****/
.userOtpContainerProperty {
    /* display: flex;
    justify-content: center;
    width: 100%;
    height: 55px; */
    /* display: flex;  */
    justify-content: center;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    display: inline-grid !important;
}

.otpInputField {
    width: 55px !important;
    text-align: center;
    height: 55px;
    border: none;
    font-family: Inter;
    font-size: 21px !important;
    font-weight: 500 !important;
    line-height: 25.41px;
    text-align: left;
    color: #333333;
}

input.otpInputField::Placeholder {
    color: #333333;
    font-size: 21px;
    font-weight: 500;
}

input.otpInputField {
    color: #333333;
    font-size: 21px;
    font-weight: 500;
}

.errorMessage {
    margin-top: 5px;
    color: red;
    /* font-size: .875em; */
    font-size: 12px !important;
}