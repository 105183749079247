.homeMainSetion {
    padding-top: 90px;
    padding-bottom: 60px;
}

.stackInnerdiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stakeholdersMain {
    margin-top: 30px;
    display: flex;
    background-color: #F4F4F4;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 10px;
}

.site_circle_img {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 21px;
}

.stakeText {
    align-content: center;
}

.stakeImage {
    align-content: center;
    margin: 20px 20px;
}

.stakeText h1 {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    color: #202020;
    font-family: inter;
    margin-bottom: 0px;

}

.siteCircleimg img {
    width: 80px;
    height: 80px;
    margin: 10px 21px 10px 21px;
}

.stakeImage img {
    width: 70px;
    height: 70px;
}

.stakeText a {
    text-decoration: none;
}

.customRow {
    justify-content: center;
    /* padding-top: 90px; */
    /* padding-bottom: 90px; */
}

/********responsive css  Home page***********/
@media (max-width: 767px) {
    .siteCircleimg img {
        width: 70px;
        height: 70px;
        margin: 10px 10px 10px 8px;
    }

    .stakeImage img {
        width: 70px;
    }

    .stakeText h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color: #202020;
        font-family: inter;
        margin-bottom: 0px;

    }

    .stakeImage {
        margin: 20px 10px !important;
    }
}

@media (max-width: 320px) {
    .siteCircleimg img {
        width: 70px;
        height: 70px;
        margin: 10px 10px 10px 8px;
    }

    .stakeImage img {
        width: 60px;
    }

    .stakeImage {
        margin: 20px 10px !important;
    }
}

.CircularProgressbar {
    width: 100px;
    height: 100px;
    margin: 10px 21px 10px 21px;
}