.stackDetailsMain {
    padding-top: 80px;

}

.stackDetailsInner {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 50px;
    margin-top: 40px;
}

.arrowImage img {
    width: 30px;
    height: 30px;
    margin-left: 5px;
}

.infoSectionBackground {
    background-color: #F8F8F8;
}

.infoSection {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 35px;

}

.stackDetailsText h1 {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Inter';
    line-height: 29px;
    margin-left: 15px;
    margin-bottom: 0px;
}

.infoSection h3 {
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}

.mainInfo_sec {
    display: flex;
    border: 1px solid #DDDDDD;
    padding: 15px;

}

.mainInfo_sec .userInfo {
    width: 50%;
}

.userInfo h4,
.userInfo1 h4 {
    font-size: 12px;
    color: #626262;
    font-weight: 400;
}


.userInfo p,
.userInfo1 p {
    font-size: 15px;
    color: #202020;
    font-weight: 500;
    margin-bottom: 0px;
    word-break: break-all;
}

.userInfo1 {
    position: relative;
    padding-left: 20px;
    width: 50%;

}

.userInfo1::before {
    content: '';
    width: 1px;
    height: 45px;
    position: absolute;
    top: 3px;
    background-color: #B9B9B9;
    margin-right: 20px;
    left: 0px;
}

ul#justify-tab-example {

    background: #F1F1F1;
}

.mtb20 {
    margin: 20px 0px;
}

.StakeholderDetails_infoSection {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 35px;
}

.stackDetailsTab {
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 50px;
    padding-bottom: 50px;
}

.stakeHOlderDetailstab {
    background-color: #F1F1F1;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
}

.holderDeailstab {
    background-color: #1D4488;
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Inter';
    line-height: 20px;
    border: 1px solid #1d4488;
}

.doteImage {
    width: 20px;
    height: 20px;
}

.stackDetailsTable thead tr th {
    background-color: #000;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    font-family: 'Inter';
}

.stackDetailsTable thead th:first-child {
    border-top-left-radius: 10px;
}

.stackDetailsTable thead th:last-child {
    border-top-right-radius: 10px
}

button#fill-tab-example-tab-longer-tab {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    line-height: 19px;
}

.stackDetailsTable tbody tr td {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    font-family: inter;
    /* line-height: 19px; */
}

.subScriptionInner {
    background-color: #F6F6F6;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    height: 100%;
    min-height: 250px;
    border-radius: 20px;
}

.peremiumText {
    background-color: #1D4488;
    border-radius: 20px 0px 0px 0px;
    width: 100%;
    max-width: 288px;
    padding-left: 20px;
}

.peremiumText h1 {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Inter';
    margin-bottom: 0px;

}

.subScriptionText {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.peremiumText {
    background-color: #1D4488;
    border-radius: 20px 0px 0px 0px;
    width: 100%;
    max-width: 250px;
    height: 100%;
    min-height: 86px;
    padding: 25px 0px 25px 20px;
}

.subScriptionBottom {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 46px;
}

.subScriptionBottom h1 {
    color: #5DA128;
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;

}

.subScriptionBottom p {
    font-size: 15px;
    margin-bottom: 20px;
    font-family: 'Inter';
    font-weight: 400;
    color: #333333;
    line-height: 24px;
}

.durationText p {
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 600;
    color: #626262;
    margin-bottom: 5px;
    line-height: 20px;
}

.durationText {
    padding: 20px 20px 0px 0px
}

.audioAlertImage {
    width: 100px;
}

.usePromptInto {
    display: flex;
    align-items: center;

}

.tableCell {
    display: flex;
    align-items: center;
}

.personProfileImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.paginationContainer {
    display: flex;
    justify-content: end;
    padding: 20px 15px;

}

.editIcon,
.deleteIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
}

.evntType {
    margin-top: 30px;
    margin-bottom: 30px;
    /* height: 50vh;
    overflow: auto; */
}

.listRecoredText {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-top: 40px;
    color: #000;

}

.tagAudioImage {
    width: 100px;
}

.personName::first-letter {
    text-transform: capitalize;
}

.evntType {
    height: 50vh;
    overflow: auto;
    margin-bottom: 20px;
    margin-top: 30px;
}

.personlist {
    height: 50vh;
    overflow: auto;
    margin-bottom: 15px;
    margin-top: 30px;
}

.caregiverlist {
    height: 40vh;
    overflow: auto;
    margin-bottom: 20px;
    margin-top: 30px;
}

.noSeniorassign {
    color: #FF0000;
    font-size: 15px;
    font-weight: 600;
}

.siteList {
    height: 50vh;
    overflow: auto;
}

.taglist {
    height: 50vh;
    overflow: auto;
}

.textuppercase::first-letter {
    text-transform: capitalize;

}

.textuppercase {
    vertical-align: top;
}

td.tdtextNotes {
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* margin: 0;
    padding: 0; */

}

.caregiverName {
    cursor: pointer;
}

.caregiverName:hover {
    color: #1D4488 !important;
    font-size: 15px;
    font-weight: 600;
    font-family: inter;
}

/***********responsive css StakeholderDetail page ********/

@media (max-width: 767px) {
    .stackDetailsInner {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-left: 20px;
        margin-top: 40px;
    }

    .stackDetailsText h1 {
        margin-left: 10px;

    }


    td.siteDateResposive {
        white-space: nowrap
    }


    .personlist {
        height: 30vh;
    }

    .caregiverlist {
        height: 30vh;

    }

    .evntType {
        height: 30vh;

    }

    .taglist {
        height: 30vh;

    }
}

.leftTablepadding {
    padding-left: 25px;


}

.rightTablepadding {
    text-align: end;
    padding-right: 25px !important;
}

.leftTableThpadding {
    width: 15%;
    padding-left: 15px;
}

.rightTableThpadding {
    text-align: end;
    padding-right: 15px !important;
    width: 30%;
}

.assignedSeniorRelation {
    width: 15%;
}