.stakeHolderListMain {
    padding-top: 90px;
    padding-bottom: 90px;
}

.stackarListMain {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;

}

.stackInner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.doteImage {
    width: 20px;
    height: 20px;
}

.arrowImage img {
    width: 30px;
    height: 30px;
}

.stakeHoldersText h1 {
    font-size: 25px;
    margin-left: 20px;
    font-weight: 600;
    margin-bottom: 0px;

}

.tableThText tr th {
    font-size: 16px;
    font-family: inter;
    font-weight: 500;
    text-align: center;
    line-height: 20px;
    color: #fff;
}

.tableTrtext tr td {
    font-size: 15px;
    text-align: center;
    font-family: inter;
    font-weight: 400;
    line-height: 20px;
    vertical-align: baseline;
}

.stakholderList {
    background-color: #000 !important;
}

.serchImage {
    width: 16px;
    height: 16px;
}

.tableList {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
}


.editIcon,
.deleteIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.backArrow {
    padding: 25px 0px;
    display: flex;
    align-items: center;
}

.backArrow span {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    margin-left: 8px;
}

.backArrow svg {
    color: #000;
    font-size: 26px;
}

.backArrowSec {
    max-width: 260px;
    width: 100%;
}

.search-form {
    margin-top: 20px;
}

.search-form .form-control {
    width: 300px;
}

.searchFeild {
    margin: 25px 0px;
    position: relative;

}

.searchFeild input {
    height: 50px;
    max-width: 370px;
    border: 1px solid #D8D8D8;
    border-radius: 8px;
}


.searchFeild svg {
    font-size: 20px;
    position: absolute;
    top: 15px;
    left: 15px;
    color: #959595;
}


.serchIconImage {
    position: relative;
}

.serchIconImage input {
    height: 50px;
    min-width: 360px;
    border: 1px solid #D8D8D8;
    padding-left: 50px;
    border-radius: 8px;
}

.settingSerchIcon {
    position: absolute;
    top: 20%;
    left: 5%;
    width: 30px;
    height: 30px;
}

/* .tableThText {
    background: #555555;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    color: white;
    border: none;
    height: 48px;
    position: sticky !important;
    top: 0 !important;
    z-index: 5 !important;
} */

/********resposive css stakeholoerlist **************/

@media (max-width: 767px) {

    .stakeHolderListMain {
        padding-top: 0px;
        padding-bottom: 90px;
    }

    .stackarListMain {
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 80px;
        display: block;
    }

    .tableList {
        margin-left: 20px;
        margin-right: 20px;

    }

    .stackInner {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 15px;
    }

    .stakeHoldersText h1 {
        font-size: 20px;
        /* margin-left: 10px; */
        font-weight: 600;
        font-family: inter;
        margin-bottom: 0px;
        padding-left: 0px;

    }
}


.listRecoredText {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-top: 40px;

}

.paginationContainer {
    display: flex;
    justify-content: end;
    padding: 15px 15px;
}

.textuppercase::first-letter {
    text-transform: capitalize;
}

td {
    white-space: break-spaces;
}


.cancelBtn {
    width: 30px;
    height: 30px;
}

.caregiverSourcePopup {
    text-align: center;
}

img.deleteImageModel {
    width: 100px;
    height: 100px;
}

.stackHolderButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1D4488;
    border-radius: 6px;
    margin: 20px 20px 20px 20px;
    padding: 0px;
    height: 50px;
}

.stackHolderButton button {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #fff !important;
    width: 100%;
    border: none;
}

.stackHolderList p {
    font-size: 21px;
    font-weight: 700;
    line-height: 27px;
    margin-top: 18px;
    margin-left: 55px;
    margin-right: 55px;
    color: #202020;
}

.caregiverSourcePopup {
    text-align: center;
}

.caregiverSourcePopup p {
    font-size: 19px;
    font-weight: 700;
    line-height: 27px;
    margin-top: 18px;
    margin-left: 55px;
    margin-right: 55px;
    color: #202020;
    margin-bottom: 0px;
}

.tableheight {
    height: 60vh;
    overflow: auto;
}