.fixedHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

}

.headerBackground {
    background-color: #1D4488;
    position: relative;
}

.logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 311px;
    background-color: #fff;
    height: 83px;
    padding-right: 50px;
    padding-top: 12px;
}

.logoImage {
    width: 100%;
    margin-left: 47px;
    padding-right: 50px;
    max-width: 260px !important;
}

.profileImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    height: 80px;
}

img.profilePhoto {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

/*****Header resposive css****/

@media (max-width: 767px) {
    .logoImage {
        width: 100%;
        margin-left: 10px;

    }
}

@media (max-width: 320px) {
    .logoImage {
        width: 100%;
        margin-left: 10px;

    }
}

@media (max-width: 320px) {
    .logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 230px;
        background-color: #fff;
        height: 83px;
        padding-right: 40px;
        padding-top: 12px;
    }
}

@media (min-width: 360px) {
    .logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 275px;
        background-color: #fff;
        height: 83px;
        padding-right: 40px;
        padding-top: 12px;
    }
}

@media (min-width: 414px) {
    .logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 320px;
        background-color: #fff;
        height: 83px;
        padding-right: 40px;
        padding-top: 12px;
    }
}

@media (min-width: 393px) {
    .logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 310px;
        background-color: #fff;
        height: 83px;
        padding-right: 40px;
        padding-top: 12px;
    }
}


.cancelBtn {
    width: 30px;
    height: 30px;
}

.upDatePassoword button {
    background-color: #1D4488 !important;
    color: #fff !important;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
}

/* .upDatePassoword .btn:hover {
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
} */

/***/

.prfileMainSection {
    margin-top: 90px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 90px;
}

.profileRowSection {
    margin-top: 50px;
}


.myProfileText {
    font-size: 24px;
    font-weight: 500;
    color: #000;
    line-height: 30px;
    /* padding-top: 50px; */
    margin-bottom: 0px;
    margin-left: 15px;
}

.basicInfor {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    line-height: 30px;

}

.basicInfroMain {
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    margin-bottom: 11px;
    text-align: center;
    min-height: 287px;
}

.basicInfroMain h4 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 600;
    color: #202020;
}

.basicInfroMain img {
    margin-top: 15px;
    margin-bottom: 15px;
}

p.profileEmailText {
    color: #626262;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 12px;
    word-break: break-word;
    line-height: 21px;
    padding-left: 10px;
    padding-right: 10px;
}

p.profileNum {
    line-height: 15px;
    font-size: 15px;
    color: #1D4488;
    font-weight: 600;
    margin-bottom: 26px;
}

.otherInfoMainSection {
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    padding-bottom: 26px;
}

.inforeAddress {
    display: flex;
    margin-left: 15px;
    margin-top: 10px;
}

.inforeAddress span {
    color: #828282;
    font-weight: 600;
    font-size: 15px;
}

.inforeAddress p {
    font-size: 15px;
    font-weight: 500;
    margin-left: 8px;
}

ul.otherProfileDetail {
    padding: 0px;
    list-style: none;
}

.profilebutton {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 40px;
}

.profilebutton button {
    background-color: #1D4488;
    color: #fff;
    height: 40px;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 6px;
    padding: 10px 23px 10px 22px;
    line-height: 20px;
}

.profilebutton button:hover {
    background-color: #1D4488;
    color: #fff;
    height: 40px;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 6px;
    padding: 10px 23px 10px 22px;
    line-height: 20px;
}

.basicInfroMain img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
}

/* .stackarListMain {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;

} */
.editProfileSection {
    padding-top: 150px;
    margin-left: 50px;
    margin-right: 50px;

}

.editProfileSection h1 {
    font-size: 24px;
    margin-bottom: 0px;
    margin-left: 15px;
    font-weight: 500;
}

.editProfileSection img {
    width: 30px;
    height: 30px;
}

.stackInner {
    display: flex;
    justify-content: start;
    align-items: center;
}

.editPrfolieFrom {
    margin-top: 50px;
}

.updateProfileButton {
    margin-top: 50px;
}

.updateProfileButton button {
    background-color: #1D4488;
    height: 40px;
    color: #ffffff;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
}

.updateProfileButton button:hover {
    background-color: #1D4488;
    height: 40px;
    color: #ffffff;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
}

.editPrfolieFrom1.form-control {
    width: 100%;
    max-width: 550px !important;
    border-radius: 3px !important;
    margin-top: 12px;
    height: 50px !important
}

.arrowProfilemain {
    padding-top: 60px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.profilearrowimgae img {
    width: 40px;
    height: 40px;
}

.preViewImageMain {
    width: 100px;
    height: 100px;
    margin-top: 15px;
    border: 2px solid #C2C2C2;
    border-radius: 50%;
}

img.prviewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.headerProfileImage {

    width: 48px;
    height: 48px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    margin: 16px 21px 16px 21px;
}