.footerMainDiv {
    background-color: #1D4488;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 9999;
}

.ptc a {
    color: #fff;
    font-size: 13px;
    margin-right: 25px;
    font-weight: 500;
    text-decoration: none;
    font-family: inter;
}

.copyRight {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

ul.ptc {
    display: flex;
    list-style: none;
    margin-top: 10px;
}

.copyRight h2 {
    /* margin-top: 10px; */
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    font-family: inter;
    margin-left: 42px;
    margin-bottom: 0px;
}