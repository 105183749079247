body {
    margin: 0;
    padding: 0;
    font-family: 'Inter' !important;
}

.table>thead {
    height: 50px;
    vertical-align: middle !important;
    position: sticky !important;
    top: 0 !important;
    z-index: 5 !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-style: none !important;

}



.form-control {
    height: 42px;
    font-size: 14px !important;
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    border: 2px solid #C2C2C2;
    border-radius: 3px;
    width: 100%;
    max-width: 550px;

}

a {
    text-decoration: none !important;
}

.btn-primary {
    --bs-btn-color: none !important;
    --bs-btn-bg: none !important;
    --bs-btn-border-color: none !important;
}

ul#fill-tab-example {
    background-color: #F1F1F1;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    margin-bottom: 20px;
}

.nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #1D4488 !important;
    border-radius: 4px !important;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Inter';


}

button#fill-tab-example-tab-caregiver {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    height: 42px;
}

button#fill-tab-example-tab-caregiver:hover {
    outline: none;
    border: none;
}

button#fill-tab-example-tab-site {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    height: 42px;
}


button#fill-tab-example-tab-Tag {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    height: 42px;
}

button#fill-tab-example-tab-site:hover {
    outline: none;
    border: none;
}

button#fill-tab-example-tab-person {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    height: 42px;
}


button#fill-tab-example-tab-device {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    height: 42px;
}

button#justify-tab-example1-tab-dailyEvent {
    width: 200px;
    height: 40px;
    color: #828282;
    /* border: 1px solid !important; */
}

.nav-tabs {
    border-bottom: none
}

ul#justify-tab-example1 {
    justify-content: center;
}

button#justify-tab-example1-tab-event {
    width: 200px;
    height: 40px;
    margin-right: 20px;
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    height: 42px;
}

button#fill-tab-example-tab-event {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    height: 42px;
}

button#fill-tab-example-tab-subscription {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    height: 42px;

}

button#fill-tab-example-tab-stakeholder {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    height: 42px;

}

button#fill-tab-example-tab-assignedSenior {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    height: 42px;
}

button#fill-tab-example-tab-person:hover {
    outline: none;
    border: none;

}

button#fill-tab-example-tab-subscription {
    outline: none;
    border: none;
}

button#dropdown {
    background: none !important;
}

.dropdown-toggle.btn.btn-primary:hover {
    background: none !important;
    border-color: none;
}

.dropdown-toggle::after {
    display: none !important
}

/* .btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    color: none !important;
    background-color: none !important;
    border-color: none !important;
} */

.btn-primary {
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: none !important;

}

.active {
    /* Styles for the active tab */
    font-weight: bold;
    /* Example style */
    color: #007bff;
    /* Example style */
}

.dropdown-item.active,
.dropdown-item:active {
    color: #000;
    text-decoration: none;
}

.dropdown-menu {
    --bs-dropdown-link-active-color: #000;
    --bs-dropdown-link-active-bg: none !important;
    ;
}

.nav-tabs .nav-link {
    margin-bottom: 0px;
    border: none !important;
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
}

.nav-link {
    display: block;
    padding: 0px;
    border: 0;
    transition: none;
}

.dropdown-item:active {
    color: #000;
}


th {
    white-space: nowrap;
}

.table>tbody {
    vertical-align: middle !important;
}


.dropdown-toggle {
    white-space: nowrap;
    border: none !important;
}


.dropdown-item:focus,
.dropdown-item:hover {
    color: none;
    background-color: none;
}

a.dropdown-item {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding: 6px 6px;
}

/********model popup csss***/


.modal-title.h4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    color: #000;
}

.addSiteProperty {
    display: flex;
    justify-content: end;
    background-color: #5DA128 !important;
    border: none !important;
    font-family: Inter;
}

#dropdown-basic {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

#dropdown-basic:focus-visible {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.modal-content {

    border-radius: 12px;
    border: 1px solid #E3E3E3;
}

/* .dropdown-menu.show {
    border: none;
} */
.dropdown-menu {
    border: none;
    /* padding: 0px; */
}

.Sidebar_settingDropdown__3FLIw.dropdown-menu.show {
    width: 100%;
}

button#dropdown-button-example2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
}

a.subLocationType.dropdown-item {
    margin-top: -3px;
}

.offSetScreen {
    margin-top: 25px;
}

button#dropdown-button-example1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    align-items: center;
}


button#dropdown-button-example2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    background-color: #F5F5F5;
    height: 50px;
    border-radius: 3px 3px 3px;
    color: #757575 !important;
    font-size: 16px;
    margin-top: 10px;
    align-items: center;
    margin-bottom: 0px !important;
}

.subLocationTypeDropdown {
    width: 100%;
    margin-top: 10px;
}


.subLocationType {
    height: 50px;
    border-radius: 3px 3px 3px;
    color: #757575 !important;
    font-size: 14px !important;
    margin-top: 10px;
    align-items: center;
    margin-bottom: 0px !important;
}

button#dropdown-button-example2 img {
    width: 18px;
}


.customTable thead th:first-child {
    border-top-left-radius: 10px;
}

.customTable thead th:last-child {
    border-top-right-radius: 10px;
}

.customTableStakeholderlist thead th:first-child {
    border-top-left-radius: 6px;
}

.customTableStakeholderlist thead th:last-child {
    border-top-right-radius: 6px;
}

a.caregiverSources.dropdown-item {
    background-color: #5da12838;
    height: 50px;
    display: flex;
    align-items: center;
    color: #5DA128;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    border-radius: 3px 3px 0px 0px;
    margin-top: 10px;
}


a.locationType.dropdown-item {
    background-color: #F5F5F5;
    height: 50px;
    display: flex;
    align-items: center;
    color: #757575;
    font-size: 13px;
    font-weight: 600;
    line-height: 12px;
    border-radius: 3px 3px 0px 0px;
    margin-top: 10px;
}

a.subLocationType.dropdown-item {
    background-color: #EDEDED;
    height: 50px;
    display: flex;
    align-items: center;
    color: #757575;
    font-size: 13px !important;
    font-weight: 600;
    line-height: 12px;
    border-radius: 3px 3px 0px 0px;
    margin-top: 10px;
}

a.privacypolicy.dropdown-item {
    height: 50px;
    display: flex;
    align-items: center;
    color: #757575;
    font-size: 13px !important;
    font-weight: 600;
    line-height: 12px;
    border-radius: 3px 3px 0px 0px;
    margin-top: 10px;
}

a.termsconditions.dropdown-item {
    height: 50px;
    display: flex;
    align-items: center;
    color: #757575;
    font-size: 13px !important;
    font-weight: 600;
    line-height: 12px;
    border-radius: 3px 3px 0px 0px;
    margin-top: 10px;
}

.modal-footer {
    border-top: none;
}

.btn:first-child:active {
    border: none;
}

.css-1jqq78o-placeholder {
    font-size: 15px;
    color: #555555;
    font-weight: 400;
}

.select__control.css-13cymwt-control {
    border: 1px solid #C2C2C2;
    border-radius: 3px;
    height: 42px;
    margin-bottom: 35px;
    font-size: 15px;
    font-family: inter;
    color: red;
    font-weight: 400;
}


.css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 35px !important;
}

a.page-link {
    color: #1D4488;
    font-size: 15px;
    padding: 6px 12px;
    font-weight: 600;
}

.active>.page-link,
.page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #1D4488;
    border-color: #1d4488;
    font-size: 16px;
    font-weight: 600;
    padding: 6px 12px;
}

select.form-select {
    height: 42px;
    font-size: 14px !important;
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    border: 2px solid #C2C2C2;
    border-radius: 3px;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
    margin: 0;
    padding: 10px 10px !important;
    /* counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9; */
    font-size: 16px;
    font-weight: 500;
    font-size: inter;
    font-family: inter;
    line-height: 30px;
}

.ql-container.ql-snow {
    border: 1px solid #dbdbdb;
    border-radius: 5px;
}

.ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    border-radius: 5px;
}


.CircularProgressbar .CircularProgressbar-path {
    stroke: #5daf34 !important;
    stroke-linecap: round;
    /* transition: stroke-dashoffset 0.5s ease 0s; */
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #1D4488 !important;
    font-size: 20px !important;
    /* dominant-baseline: middle; */
    text-anchor: middle;
    font-weight: 700 !important;
    font-family: 'Inter';
}


td.notestd {
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 70px;

}

/* .notesSection {
    max-width: 200px;
} */
.notesSection {
    max-width: 200px;
    padding-top: 13px;
}

.invalid-feedback {
    font-size: 15px !important;
}

li.previous {
    display: none;
}

li.next {
    display: none;
}