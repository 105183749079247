.addSiteProperty {
    display: flex;
    justify-content: end;
    background-color: #5DA128 !important;
    border: none !important;
    font-family: Inter;
}

.sidePadding {
    padding: 10px !important;
}

.setLogoOnDashboard {
    padding: 8px 20px 8px 20px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid;
    border-bottom: 1px solid #DFDFDF !important;
}

#dropdown-basic {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

#dropdown-basic:focus-visible {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.settingDropdown {
    border: none;
}

.settingDropDownText {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 15px;
    color: #5DA128 !important;
}

.settingDropDownText:hover {
    color: #5DA128;
}

.locationImage {
    width: 18px;
    /* transform: rotate(-180deg); */
    transition: transform 0.3s ease;
}

.Sidebar_settingDropdown__3FLIw.dropdown-menu.show {
    width: 100%;
}

button#dropdown-button-example2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

p.settingText {
    padding-left: 12px;
    margin-bottom: 0px;

}

p.settingText::before {
    content: '';
    width: 4px;
    height: 25px;
    background: #5DA128;
    position: absolute;
    padding-left: 0px;
    left: 10px;
    top: 1px;
}

a.careigverSources.dropdown-item {
    background-color: #5da12838;
    height: 50px;
    display: flex;
    align-items: center;
    color: #5DA128;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    border-radius: 3px 3px 0px 0px;
}

p.locationTypePrea {
    margin-top: 17px;
    font-weight: 500;
}