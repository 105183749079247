.settingMainSection {
    padding-top: 82px;
}

.mainSidebar {
    display: flex;
}

.seetingLeftSidebar {
    width: 20%;
    border: 1px solid #DFDFDF;
    height: 100vh;
    max-width: 310px;
    margin-top: 1px;
    z-index: 999;
}

.seetingRightSidebar {
    width: 80%;
}

.serchIconImage input {
    height: 50px;
    min-width: 360px;
    border: 1px solid #D8D8D8;
    padding-left: 50px;
    border-radius: 8px;
}

.sechSeetingmain {
    display: flex;
    justify-content: space-between;
    margin-top: 41px;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
}

.settingDotImage {
    text-align: end;
    padding-right: 20px;
}

.seetingArrowImage {
    display: flex;
}

th.settingBackgroundAction {
    display: flex;
    justify-content: flex-end;
    background: #000;
    color: #fff;
    height: 50px;
}

.seetingArrowImage {
    display: flex;
    align-items: center;
}

.seetingBackArrow {
    width: 30px;
    height: 30px;
    margin-right: 24px;
}

.serchIconImage {
    position: relative;
}

.settingSerchIcon {
    position: absolute;
    top: 20%;
    left: 5%;
    width: 30px;
    height: 30px;
}

.settingPermissionTable {
    margin: 30px 30px 0px 30px;
    background-color: #F5F5F5;
    /* height: 550px; */
    border: 1px solid #E6e6E6;
    border-radius: 10px;
    /* overflow-x: scroll;
    overflow-y: hidden; */
    height: 50vh;
    overflow: auto;


}


th.settingBackground {
    background-color: #000;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;

}

.doteImage {
    width: 30px;
    height: 30px;
}

.addNewButton button {
    font-size: 15px;
    font-weight: 600;
    color: #fff !important;
    display: flex;
    align-items: center;
    background-color: #5DA128 !important;
    height: 40px;
    border-radius: 8px;

}

/* .addNewButton button:hover {
    color: #fff !important;
    border: none !important;
    background-color: #5DA128;
} */

.SelectSeting {
    width: 100%;
    max-width: 100% !important;
}

select.seetingCustomSelect {
    width: 100%;
    height: 50px;
    border: none;
}

.addNewTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1D4488;
    border-radius: 6px;
    margin: 20px 20px 20px 20px;
    padding: 0px;
    height: 50px;
}

.addNewTitle button {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #fff !important;
    width: 100%;
    border: none;

}

/* .addNewTitle button:hover {
    color: #fff;
    border-color: none;
} */

.cancelBtn {
    width: 30px;
    height: 30px;
}

td.caregiverSourceText {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Inter';
    color: #202020;
    line-height: 19px;
}

/* .doteImage {
    width: 30px;
    height: 30px;
} */
img.careGiverDoteImage {
    width: 30px;
    height: 30px;
    margin-left: -50px;
}

a.caregiverdropdownItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.listRecoredText {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-top: 40px;

}

.caregiverSourcePopup {
    text-align: center;
}

img.deleteImageModel {
    width: 100px;
    height: 100px;
}

.caregiverSourcePopup p {
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    margin-top: 18px;
    margin-left: 55px;
    margin-right: 55px;
    color: #202020;
    margin-bottom: 0px;
}

.termsConditionstMain {
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
}

.termsConditionstText {
    display: flex;
    justify-content: center;
    align-items: center;
}

.termsConditionstText h1 {
    margin-bottom: 0px;
    font-size: 30px;
    font-weight: 700;
    color: #000;

}

.privacyPolicyText {
    margin-top: 30px;
}

.privacyBtnMain {
    display: flex;
    justify-content: end;
    /* height: 50px; */
    margin-top: 25px;
    margin-bottom: 60px;

}

button.privacyBtn {
    background-color: #1d4488;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    padding: 0px 25px;
    border-radius: 5px;
    height: 42px;
}

button.privacyBtn:hover {
    background-color: #1d4488;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    padding: 0px 25px;
    border-radius: 5px;
}