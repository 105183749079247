.loginLable {
    margin-bottom: 35px;
}

.customPasswordfiled {
    margin-bottom: 35px;
}


.labelUsername {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Inter';
}

.selectInputFiled {
    margin-bottom: 35px;
}